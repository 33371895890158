import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../components/link'
import { Contact } from '../utils/navLinks'
import ProjectSection from '../components/projectSection'

import Talk from '../assets/svg/webUXIcons/talk.svg'
import One from '../assets/svg/webUXIcons/one.svg'
import Two from '../assets/svg/webUXIcons/two.svg'
import Three from '../assets/svg/webUXIcons/three.svg'
import Four from '../assets/svg/webUXIcons/four.svg'

const Services = () => {
    const { t } = useTranslation('services')

    return (
        <Layout>
            <SEO title="services:title" description="services:webUIUXDescription" />
            <div className="webUXsection">
                <h1 className="header">{t('webUIUXTitle')}</h1>
                <p className="paragraph">{t('webUIUXDescription')}</p>
                <div className="webUIUXSurface">
                    <StaticImage
                        src="../assets/images/webUIUX.png"
                        alt={t('webUIUXTitle')}
                        className="webUIUXPicture"
                    />
                </div>
            </div>
            <div className="yourSiteSection">
                <div className="yourSiteDescription">
                    <h2 className="header">{t('yourSiteTitle')}</h2>
                    <p className="paragraph">{t('yourSiteDescription')}</p>
                </div>
                <div className="yourSiteSurface">
                    <StaticImage
                        src="../assets/images/yourSite.png"
                        alt={t('yourSiteTitle')}
                        className="yourSitePicture"
                    />
                </div>
            </div>
            <div className="workingWithMeSection">
                <div className="workingWithMeDescription">
                    <h2 className="header">{t('workingWithMeTitle')}</h2>
                    <p className="paragraph">{t('workingWithMeDescription')}</p>
                </div>
                <div className="talkSurface">
                    <Talk className="talkImage" />
                </div>
            </div>
            <div className="processSection">
                <h2 className="header">Process</h2>
                <div className="processGrid">
                    <div className="processCard">
                        <One />
                        <div className="processDescription">
                            <h4>{t('processStep1Title')}</h4>
                            <p className="paragraph">{t('processStep1Description')}</p>
                        </div>
                    </div>
                    <div className="processCard">
                        <Two />
                        <div className="processDescription">
                            <h4>{t('processStep2Title')}</h4>
                            <p className="paragraph">{t('processStep2Description')}</p>
                        </div>
                    </div>
                    <div className="processCard">
                        <Three />
                        <div className="processDescription">
                            <h4>{t('processStep3Title')}</h4>
                            <p className="paragraph">{t('processStep3Description')}</p>
                        </div>
                    </div>
                    <div className="processCard">
                        <Four />
                        <div className="processDescription">
                            <h4>{t('processStep4Title')}</h4>
                            <p className="paragraph">{t('processStep4Description')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ProjectSection />
            <div className="getAQuoteSection">
                <h2 className="header">{t('contact:getAQuote')}</h2>
                <p className="paragraph">{t('contact:description')}</p>
                <Button
                    disableElevation
                    component={Link}
                    to={Contact.path}
                    variant="contained"
                    className="containedButton"
                >
                    {t('contact:contactMe')}
                </Button>
            </div>
        </Layout>
    )
}

export default Services
